import httpClient  from '../api'; 
const API_CONTROLLER = 'repair-advance-payment/'; 


export default { 
    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },
    
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                code: search.code
            }
        });
    },

    getForApprove(id) { 
        let url = API_CONTROLLER + 'get-for-approve';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getForApproves(search) { 
        let url = API_CONTROLLER + 'get-for-approves';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                staffStatus: search.staffStatus,
                code: search.code
            }
        });
    },

    submitApprove(data) { 
        let url = API_CONTROLLER + 'submit-approved';
        return httpClient.post(url, data);
    },

    getForInvoice(search) { 
        let url = API_CONTROLLER + 'get-for-invoice';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                keyword: search.keyword
            }
        });
    },
}